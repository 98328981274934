import React from 'react'
import {Container, Row} from 'react-bootstrap';
import styled from 'styled-components';

import {ArrowDown} from 'react-bootstrap-icons';
import {config} from 'react-spring'
import {Spring} from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import scrollTo from 'gatsby-plugin-smoothscroll';

const bg = '/images/herobg.jpg'

const Hero = (props) => (
    <Wrap className="my-auto" fluid>
      <HeroContainer>
        <Logo
            alt={`logo`}
            src={`/images/logo/carpendev_logo_color.png`}
        />
        <HeroTitle>{props.title}</HeroTitle>
        <HeroP>{props.content}</HeroP>
      </HeroContainer>
      <AnimatedRow>
        <Arrow size={'13vh'} style={{transition: '0.3s'}}
               onClick={(event) => scrollOnClick(event, "#mission")}/>
      </AnimatedRow>
    </Wrap>
);
const scrollOnClick = (event, hash) => {
  event.preventDefault();
  scrollTo(hash)
}

const Arrow = styled(ArrowDown)`
     &:hover, &:active,&:focus {
      color:grey;
      height:15vh;
     }
`

const AnimatedRow = (props) => {
  const yTranslation = props.reverseAnimation ? "200px" : "-100px"
  var executed = false
  return (
      <VisibilitySensor partialVisibility offset={{bottom: 100, top: 500}}>
        {({isVisible}) => (
            <Spring delay={200} config={config.slow} to={
              {
                opacity: isVisible,
                transform: isVisible
                    ? "translateY(0)"
                    : `translateY(${yTranslation})`
              }}>
              {(transformation) => {
                if (!executed) {
                  executed = transformation.opacity === 1
                }
                return (
                    <Row noGutters="true" style={{
                      overflow: "hidden",
                      minHeight: "200px", ...transformation
                    }} className="justify-content-center no-gutters">
                      {props.children}
                    </Row>
                )
              }}
            </Spring>
        )}
      </VisibilitySensor>
  )
}

export default Hero;

const Wrap = styled(Container)`
    height: 95vh;
    padding: 0;
`

const Logo = styled.img`
    max-width: 20%;
    min-width: 15em;
    height: auto;
    max-height: 25vh;
    object-fit: scale-down;    
  `;

const HeroTitle = styled.h1`
    font-size:calc(12px + 4vw);    
    text-align: center;
    padding: 1vw;
  `

const HeroP = styled.p`
    font-size:calc(12px + 1vw);
    margin-top: 10vh;
    text-align: center;
    @media (max-width: 768px) {
      margin-top: 0
  }
`;

const ContainerStyled = styled.div`
    color: whitesmoke; 
    height: 80vh;
    background-image: url(${bg});
    background-size:cover;
    background-repeat:no-repeat;
    position:relative;    
    overflow:hidden;
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    box-shadow: inset 0 0 0 1000px rgba(10,42,73,.50);
     @media (max-width: 768px) {
      height: 70vh;
      clip-path: polygon(0 0, 100% 0, 100% 67vh, 0 100%);
  }
  `;

const Content = styled.div`  
    z-index:2; 
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    height: 80vh;
    @media (max-width: 768px) {
      height: 70vh;
  }
  `;

const HeroContainer = (props) => {
  return (
      <ContainerStyled id='home'>
        <Content>
          {props.children}
        </Content>
      </ContainerStyled>
  );
};

